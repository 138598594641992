import DesktopMenuColumn from 'components/DesktopMenu/DesktopMenuColumn';
import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import Logo from 'components/Logo';
import Story from 'components/Story';
import { Special } from 'components/Typography';
import { range } from 'fp-ts/lib/NonEmptyArray';
import useNavigation from 'hooks/useNavigation';
import usePageScrolling from 'hooks/usePageScrolling/usePageScrolling';
import { triggerMainNavigation } from 'lib/gtag';
import { getApiLocale } from 'lib/locale';
import { DesktopNavigationModel } from 'models/navigation/types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useCallback, useId } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

const Wrapper = styled.header<{
  dark?: boolean;
  overlay?: boolean;
  isVisible?: boolean;
  isAtTop?: boolean;
}>(
  ({ theme: { bp, colors }, dark, overlay, isVisible, isAtTop }) => css`
    flex-direction: row;
    display: none;
    position: sticky;
    top: 0;
    height: 80px;
    z-index: 4;
    background-color: ${overlay && isAtTop ? 'transparent' : colors.white};
    transform: ${isVisible ? 'translateY(0)' : 'translateY(-100%)'};
    transition-property: background-color, transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${colors.white};
      opacity: 0;
      z-index: -1;
      transition: opacity 300ms;
      pointer-events: none;
    }

    ${Special} {
      transition: color 300ms;
      color: ${dark && isAtTop ? colors.white : colors.black};
    }

    ${MegaMenu} {
      opacity: ${!isVisible && '0 !important'};
    }

    ${Logo} {
      path {
        fill: ${dark && isAtTop ? colors.white : colors.black};
      }
    }

    &:hover,
    &:focus-within {
      &:after {
        opacity: 1;
      }

      ${Special} {
        color: ${colors.black};
      }

      ${Logo} {
        path {
          fill: ${colors.black};
        }
      }
    }

    ${bp.l} {
      display: flex;
    }
  `
);

const MegaMenu = styled.nav(
  ({ theme: { bp, colors } }) => css`
    position: absolute;
    top: 80px;
    width: 100%;
    background-color: ${colors.white};
    display: none;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms;

    ${bp.l} {
      display: flex;

      &:hover,
      &:focus-within {
        opacity: 1;
        pointer-events: auto;
      }
    }
  `
);

const MegaMenuList = styled.div`
  display: block;
  position: relative;
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MegaMenuTopic = styled.div`
  vertical-align: top;
  display: inline-block;
  height: 100%;
  white-space: normal;
  width: 0;
  overflow: hidden;
  pointer-events: none;

  &:hover,
  &:focus-within,
  &:focus {
    width: 100%;
    pointer-events: auto;
  }
`;

const MegaMenuScaler = styled.div`
  width: 100%;
`;

const TopMenuLink = styled.div<{ $offset?: boolean }>(
  ({ theme: { bp, colors }, $offset }) => css`
    background: none;
    border: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 80px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 0 12px;
    text-decoration: none;
    position: relative;
    margin-left: ${$offset ? 'auto !important' : 0};
    cursor: pointer;
    outline-offset: -1;

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 12px;
      right: 12px;
      margin-top: 10px;
      height: 1px;
      background-color: ${colors.black};
      opacity: 0;
      transition: opacity 300ms;
    }

    &:hover:after,
    &:focus-within:after {
      opacity: 1;
    }

    &:first-child {
      margin-left: 26px;

      ${bp.xl} {
        margin-left: 38px;
      }
    }

    &:last-child {
      margin-right: 26px;

      ${bp.xl} {
        margin-right: 38px;
      }
    }
  `
);

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const createHoverStyles = ({
  topLevelLinkCount = 0,
}: {
  topLevelLinkCount?: number;
}) => {
  const menusOnHover = range(1, topLevelLinkCount).map(
    n => `${TopMenuLink}:nth-child(${n}):hover ~ ${MegaMenu}`
  );
  const menusOnFocus = range(1, topLevelLinkCount).map(
    n => `${TopMenuLink}:nth-child(${n}):focus ~ ${MegaMenu}`
  );

  const menuItemsOnHover = menusOnHover.map(
    (menu, i) => `${menu} ${MegaMenuTopic}:nth-child(${i + 1})`
  );
  const menuItemsOnFocus = menusOnFocus.map(
    (menu, i) => `${menu} ${MegaMenuTopic}:nth-child(${i + 1})`
  );

  return css`
    ${[...menusOnHover, ...menusOnFocus].join(',')} {
      opacity: 1;
      pointer-events: auto;
    }

    ${[...menuItemsOnHover, ...menuItemsOnFocus].join(',')} {
      width: 100%;
      pointer-events: auto;
    }
  `;
};

const HoverStyles = createGlobalStyle<{ topLevelLinkCount?: number }>(
  createHoverStyles
);

interface DesktopMenuProps {
  navigation: DesktopNavigationModel | null;
  dark?: boolean;
  overlay?: boolean;
  loggedIn: boolean;
  bagCount: number;
  locale: string;
  openSearch: (id: string) => void;
  openAuth: (id: string) => void;
  openCountryPicker: () => void;
  isGrapheneEnabled?: boolean;
}

const DesktopMenu: FC<DesktopMenuProps> = ({
  navigation,
  dark,
  overlay,
  loggedIn,
  bagCount,
  locale,
  openSearch,
  openAuth,
  openCountryPicker,
}) => {
  const { asPath, query } = useRouter();
  const { isScrollingDown, isAtTop } = usePageScrolling();
  const { isGrapheneEnabled } = useNavigation();

  const { country, displayCurrency } = getApiLocale(locale);

  const searchButtonId = useId();

  const maybeAddGrapheneSlug = useCallback(
    (link: string): string =>
      isGrapheneEnabled
        ? link.replace(query.locale as string, `${query.locale}/graphenehc`)
        : link,
    [query.locale, isGrapheneEnabled]
  );

  return (
    <>
      <HoverStyles
        key="hoverStyles"
        topLevelLinkCount={navigation?.primary.length}
      />
      <Wrapper
        isVisible={isAtTop || !isScrollingDown}
        isAtTop={isAtTop}
        dark={dark}
        overlay={overlay}
      >
        {navigation?.primary.map(({ id, link, path }) => (
          <Link
            legacyBehavior={true}
            key={id}
            href={maybeAddGrapheneSlug(link.link)}
            passHref={true}
          >
            <TopMenuLink
              as="a"
              onClick={() => {
                triggerMainNavigation(link, path, global.window);
              }}
            >
              <Special variant="1" size="L">
                {link.title}
              </Special>
            </TopMenuLink>
          </Link>
        ))}
        <MegaMenu key={asPath}>
          <MegaMenuList>
            {navigation?.primary.map(
              ({
                link,
                id,
                primaryDesktopColumnOne,
                primaryDesktopColumnTwo,
                primaryDesktopColumnThree,
                desktopStory,
                desktopStoryColumnWidth,
              }) => (
                <MegaMenuTopic key={id} aria-label={link.title}>
                  <MegaMenuScaler>
                    <Grid columns={12}>
                      <DesktopMenuColumn
                        starts={1}
                        menus={primaryDesktopColumnOne}
                      />
                      <DesktopMenuColumn
                        starts={{ xs: 4, xl: 3 }}
                        menus={primaryDesktopColumnTwo}
                      />
                      <DesktopMenuColumn
                        starts={{ xs: 7, xl: 5 }}
                        menus={primaryDesktopColumnThree}
                      />
                      <Column
                        starts={{ xs: 10, xl: 7 }}
                        spans={{ xs: 3, xl: desktopStoryColumnWidth }}
                      >
                        {desktopStory ? (
                          <>
                            <Gap size={{ xs: 16, xl: 32 }} />
                            <Story {...desktopStory} />
                            <Gap size={{ xs: 32, xl: 64 }} />
                          </>
                        ) : null}
                      </Column>
                    </Grid>
                  </MegaMenuScaler>
                </MegaMenuTopic>
              )
            )}
          </MegaMenuList>
        </MegaMenu>

        <LogoContainer>
          <Link legacyBehavior={true} href={`/${locale}`} passHref={true}>
            <a title="Perfect Moment" aria-label="Perfect Moment - Home">
              <Logo />
            </a>
          </Link>
        </LogoContainer>
        <TopMenuLink
          id={searchButtonId}
          as="button"
          onClick={() => openSearch(searchButtonId)}
          $offset={true}
        >
          <Special variant="1" size="L">
            Search
          </Special>
        </TopMenuLink>
        {loggedIn ? (
          <Link
            legacyBehavior={true}
            href={`/${locale}/account/details`}
            passHref={true}
          >
            <TopMenuLink as="a">
              <Special variant="1" size="L">
                Account
              </Special>
            </TopMenuLink>
          </Link>
        ) : (
          <TopMenuLink
            id="desktop-sign-in"
            as="button"
            onClick={() => openAuth('desktop-sign-in')}
          >
            <Special variant="1" size="L">
              Sign in
            </Special>
          </TopMenuLink>
        )}
        <TopMenuLink
          as="button"
          onClick={openCountryPicker}
          aria-label={`Change shipping location. ${country} - ${displayCurrency} selected`}
        >
          <Special variant="1" size="L">
            {country} / {displayCurrency}
          </Special>
        </TopMenuLink>
        <Link legacyBehavior={true} href={`/${locale}/bag`} passHref={true}>
          <TopMenuLink as="a">
            <Special variant="1" size="L">{`Bag (${bagCount})`}</Special>
          </TopMenuLink>
        </Link>
      </Wrapper>
    </>
  );
};

export default DesktopMenu;
