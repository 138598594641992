import Gap from 'components/Gap';
import { Column, ColumnProps } from 'components/Grid';
import { Title } from 'components/Typography';
import useNavigation from 'hooks/useNavigation';
import { triggerMainNavigation } from 'lib/gtag';
import { DesktopNavigationLevel3Model } from 'models/navigation/types';
import RouterLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, Fragment, memo, useCallback } from 'react';
import styled, { css } from 'styled-components';

interface DesktopMenuColumnProps {
  menus: DesktopNavigationLevel3Model[];
  starts: ColumnProps['starts'];
}

const NavColumn = styled(Column)(
  ({ theme: { bp } }) => css`
    margin-top: 16px;

    ${bp.xl} {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  `
);

const Level2LinkTitle = styled(Title)`
  text-decoration: none;
`;

const Level3LinkTitle = styled(Title)`
  display: block;
  text-decoration: none;
`;

const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const DesktopMenuColumn: FC<DesktopMenuColumnProps> = ({ menus, starts }) => {
  const { query } = useRouter();
  const { isGrapheneEnabled } = useNavigation();

  const maybeAddGrapheneSlug = useCallback(
    (link: string): string =>
      isGrapheneEnabled
        ? link.replace(query.locale as string, `${query.locale}/graphenehc`)
        : link,
    [query.locale, isGrapheneEnabled]
  );

  return (
    <NavColumn starts={starts} spans={{ xs: 3, xl: 2 }}>
      {menus.map(menu => (
        <Fragment key={menu.id}>
          <RouterLink
            legacyBehavior={true}
            href={maybeAddGrapheneSlug(menu.link.link)}
            passHref
          >
            <Level2LinkTitle
              as="a"
              variant="3"
              size={{ xs: '2XS', xl: 'XS' }}
              onClick={() =>
                triggerMainNavigation(menu.link, menu.path, global.window)
              }
            >
              {menu.link.title}
            </Level2LinkTitle>
          </RouterLink>
          <Gap size={16} />
          <LinkList aria-label={menu.link.title}>
            {menu.primary.map(menuItem => (
              <li key={menuItem.id}>
                <RouterLink
                  legacyBehavior={true}
                  href={maybeAddGrapheneSlug(menuItem.link.link)}
                  passHref
                >
                  <Level3LinkTitle
                    variant="1"
                    as="a"
                    size={{ xs: '2XS', xl: 'XS' }}
                    onClick={() =>
                      triggerMainNavigation(
                        menuItem.link,
                        menuItem.path,
                        global.window
                      )
                    }
                  >
                    {menuItem.link.title}
                  </Level3LinkTitle>
                </RouterLink>
                <Gap size={8} />
              </li>
            ))}
          </LinkList>
          <Gap
            size={menu.primary.length ? { xs: 24, xl: 40 } : { xs: 16, xl: 32 }}
          />
        </Fragment>
      ))}
    </NavColumn>
  );
};

export const comparator = (
  prev: DesktopMenuColumnProps,
  next: DesktopMenuColumnProps
) => {
  const prevIds: string[] = prev.menus.flatMap(menu => [
    menu.id,
    ...menu.primary.map(primary => primary.id),
  ]);

  const nextIds: string[] = next.menus.flatMap(menu => [
    menu.id,
    ...menu.primary.map(primary => primary.id),
  ]);

  return prev.starts === next.starts && prevIds.join(',') === nextIds.join(',');
};

export default memo(DesktopMenuColumn, comparator);
